import * as React from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { checkEmailExist } from '../../services/auth';
import { useLogin } from '../../contexts/LoginContext';
import { Link } from 'react-router-dom';

const schemaValidationLogin = yup.object({
  email: yup.string().email('Format email harus seperti: example@mail.com').required('Email harus diisi'),
});

const FormCheckEmail: React.FC = () => {
  const { email, setEmail } = useLogin();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schemaValidationLogin),
  });

  const onSubmit = async (values: { email: string }) => {
    const res = await checkEmailExist(values.email);
    if (!res.success)
      return toast({
        status: 'error',
        description: res.message,
      });

    setEmail({
      ...email,
      value: values.email,
      isExist: res.data.is_exist,
      needActivate: res.data.need_activation,
    });

    if (res.data.is_exist && !res.data.need_activation) return;

    onOpen();
  };

  const finalRef = React.useRef<HTMLInputElement | null>(null);
  const { ref, ...rest } = register('email');

  return (
    <Stack spacing={4}>
      <Dialog isOpen={isOpen} onClose={onClose} finalFocusRef={finalRef} />
      <form action="#" method="post" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          <FormControl id="email" isInvalid={Boolean(errors.email)}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              ref={e => {
                ref(e);
                finalRef.current = e;
              }}
              {...rest}
            />
            <FormHelperText fontSize="xs" display={!errors.email ? 'flex' : 'none'}>
              Contoh: example@mail.com
            </FormHelperText>
            <FormErrorMessage>{errors.email && errors.email?.message}</FormErrorMessage>
          </FormControl>
          <Stack spacing={4}>
            <Button
              type="submit"
              colorScheme={useColorModeValue('secondary', 'primary')}
              isLoading={isSubmitting}
              isDisabled={!isValid}
            >
              Selanjutnya
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

const Dialog: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  finalFocusRef: React.RefObject<HTMLInputElement>;
}> = ({ isOpen, onClose, finalFocusRef }) => {
  const cancelRef = React.useRef(null);
  const { email } = useLogin();

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      finalFocusRef={finalFocusRef}
      isCentered
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          {!email.isExist && !email.needActivate ? 'Email belum terdaftar' : 'Email belum diaktivasi'}
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          {!email.isExist && !email.needActivate
            ? 'Lanjutkan mendaftar dengan email'
            : 'Kirim ulang kode aktivasi ke email'}{' '}
          {email.value}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button onClick={onClose}>Ubah</Button>
          <Button
            as={Link}
            to={email.isExist && email.needActivate ? '/resend' : '/signup'}
            ref={cancelRef}
            colorScheme="secondary"
            ml={3}
          >
            {!email.isExist && !email.needActivate ? 'Ya, Daftar' : 'Kirim ulang'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default FormCheckEmail;
