import { http, httpAuth } from '../utils/httpService';
import axios, { AxiosError } from 'axios';
import { ApiResponse, User } from '../interfaces/http';
import { initialUserState } from '../interfaces/state';

const forgotPassword = async (postData: { email: string }): Promise<ApiResponse<null>> => {
  try {
    const { data }: { data: ApiResponse<null> } = await http.post(
      `${process.env.REACT_APP_BASE_URL_API}/member/forgotPassword`,
      postData
    );
    return data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ApiResponse<null>>;
      if (serverError && serverError.response) {
        return serverError.response.data;
      }
    }

    return {
      success: false,
      code: 500,
      message: 'Something when wrong!',
      data: null,
    };
  }
};

const updateProfile = async (postData: User): Promise<ApiResponse<User>> => {
  try {
    const { data }: { data: ApiResponse<User> } = await httpAuth.post(
      `${process.env.REACT_APP_BASE_URL_API}/user/update_profile`,
      postData
    );
    return data;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ApiResponse<User>>;
      if (serverError && serverError.response) {
        return serverError.response.data;
      }
    }

    return {
      success: false,
      code: 500,
      message: 'Something when wrong!',
      data: initialUserState,
    };
  }
};

const getProfile = async (): Promise<ApiResponse<User>> => {
  try {
    const { data }: { data: ApiResponse<User> } = await httpAuth.get(
      `${process.env.REACT_APP_BASE_URL_API}/user/profile`
    );

    return data;
  } catch (error: unknown | string) {
    if (axios.isAxiosError(error)) {
      if (error?.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        const serverError = error as AxiosError<ApiResponse<User>>;
        if (serverError && serverError.response) {
          if (!serverError.response.data.success) {
            return {
              success: false,
              code: serverError?.response.status,
              message: serverError?.response.statusText,
              data: initialUserState,
            };
          }

          return serverError.response.data;
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        return {
          success: false,
          code: 400,
          message: `Error Request! ${error.message}`,
          data: initialUserState,
        };
      } else {
        // Something happened in setting up the request and triggered an Error

        return {
          success: false,
          code: 500,
          message: 'Something when wrong!',
          data: initialUserState,
        };
      }
    }

    return {
      success: false,
      code: 500,
      message: (error as string) || 'Something when wrong!',
      data: initialUserState,
    };
  }
};

export { getProfile, updateProfile, forgotPassword };
