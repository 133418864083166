import * as React from 'react';
import { Button } from '@chakra-ui/react';
import { ButtonProps } from '@chakra-ui/button';
import { Link as RouteLink, LinkProps as RouterLinkProps } from 'react-router-dom';

type LinkButtonProps = ButtonProps & RouterLinkProps;

export const LinkButton: React.FC<LinkButtonProps> = React.forwardRef<HTMLButtonElement, LinkButtonProps>(
  (props: LinkButtonProps, ref) => {
    return <Button ref={ref} as={RouteLink} {...props} />;
  }
);
LinkButton.displayName = 'LinkButton';
