import axios, { AxiosError } from 'axios';
import { ApiResponse, checkEmailExistRes, LoginReq, LoginRes, SignupReq, Token } from '../interfaces/http';
import { initialTokenState, initialUserState } from '../interfaces/state';
import { http } from '../utils/httpService';

const login = async (values: LoginReq): Promise<ApiResponse<LoginRes>> => {
  try {
    const { data: response }: { data: ApiResponse<LoginRes> } = await http.post(
      `${process.env.REACT_APP_BASE_URL_API}/auth/login`,
      {
        email: values.email,
        password: values.password,
      },
      { withCredentials: true }
    );

    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ApiResponse<LoginRes>>;
      if (serverError && serverError.response) {
        return serverError.response.data;
      }
    }

    return {
      success: false,
      code: 500,
      message: 'Something when wrong!',
      data: {
        user: initialUserState,
        token: initialTokenState,
      },
    };
  }
};

const logout = async () => {
  try {
    const response = await http.get(`${process.env.REACT_APP_BASE_URL_API}/auth/logout`, { withCredentials: true });

    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError;
      if (serverError && serverError.response) {
        return serverError.response;
      }
    }

    return {
      success: false,
      code: 500,
      message: 'Something when wrong!',
    };
  }
};

const checkEmailExist = async (email: string): Promise<ApiResponse<checkEmailExistRes>> => {
  try {
    const { data: response }: { data: ApiResponse<checkEmailExistRes> } = await http.post(
      `${process.env.REACT_APP_BASE_URL_API}/auth/check_email`,
      {
        email: email,
      }
    );

    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error?.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        const serverError = error as AxiosError<ApiResponse<checkEmailExistRes>>;
        if (serverError && serverError.response) {
          if (!serverError.response.data.success) {
            return {
              success: false,
              code: serverError?.response.status,
              message: serverError?.response.statusText,
              data: {
                is_exist: false,
                need_activation: false,
              },
            };
          }

          return serverError.response.data;
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        return {
          success: false,
          code: 400,
          message: `Error Request! ${error.message}`,
          data: {
            is_exist: false,
            need_activation: false,
          },
        };
      } else {
        // Something happened in setting up the request and triggered an Error

        return {
          success: false,
          code: 500,
          message: 'Something when wrong!',
          data: {
            is_exist: false,
            need_activation: false,
          },
        };
      }
    }

    return {
      success: false,
      code: 500,
      message: 'Something when wrong!',
      data: {
        is_exist: false,
        need_activation: false,
      },
    };
  }
};

const signUp = async (params: SignupReq): Promise<ApiResponse<null>> => {
  try {
    const { data: response }: { data: ApiResponse<null> } = await http.post(
      `${process.env.REACT_APP_BASE_URL_API}/auth/signup`,
      params
    );

    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const serverError = error as AxiosError<ApiResponse<null>>;
      if (serverError && serverError.response) {
        return serverError.response.data;
      }
    }

    return {
      success: false,
      code: 0,
      message: 'Something when wrong!',
      data: null,
    };
  }
};

const refreshToken = async (): Promise<ApiResponse<Token>> => {
  try {
    const { data: response }: { data: ApiResponse<Token> } = await http.get(
      `${process.env.REACT_APP_BASE_URL_API}/auth/refresh_token`
    );

    return response;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error?.response) {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */
        const serverError = error as AxiosError<ApiResponse<Token>>;
        if (serverError && serverError.response) {
          if (!serverError.response.data.success) {
            return {
              success: false,
              code: serverError?.response.status,
              message: serverError?.response.statusText,
              data: { access_token: '' },
            };
          }

          return serverError.response.data;
        }
      } else if (error.request) {
        /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
        return {
          success: false,
          code: 400,
          message: `Error Request! ${error.message}`,
          data: { access_token: '' },
        };
      } else {
        // Something happened in setting up the request and triggered an Error

        return {
          success: false,
          code: 500,
          message: 'Something when wrong!',
          data: { access_token: '' },
        };
      }
    }

    return {
      success: false,
      code: 500,
      message: 'Something when wrong!',
      data: { access_token: '' },
    };
  }
};

export { login, logout, signUp, checkEmailExist, refreshToken };
