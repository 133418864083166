import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/600.css';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import { Routes, Route } from 'react-router-dom';
import Main from './components/layout/Main';
import Home from './pages/Home';
import Login from './pages/login/Login';
import Pricing from './pages/Pricing';
import Account from './pages/Account';
import { AuthProvider } from './contexts/AuthContext';
import NotRequireAuth from './components/NotRequireAuth';
import NotFound from './pages/404';
import { LoginProvider } from './contexts/LoginContext';
import SignUp from './pages/signup/SignUp';

export default function App() {
  return (
    <AuthProvider>
      <LoginProvider>
        <ChakraProvider resetCSS theme={theme}>
          <Routes>
            <Route
              path="/login"
              element={
                <NotRequireAuth>
                  <Login />
                </NotRequireAuth>
              }
            />
            <Route
              path="/signup"
              element={
                <NotRequireAuth>
                  <SignUp />
                </NotRequireAuth>
              }
            />
            <Route element={<Main />}>
              <Route path="/" element={<Home />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/account" element={<Account />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ChakraProvider>
      </LoginProvider>
    </AuthProvider>
  );
}
