import * as React from 'react';
import { Link as RouteLink, useNavigate, useLocation } from 'react-router-dom';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Link,
  Button,
  Text,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { RiEyeLine, RiEyeCloseLine } from 'react-icons/ri';
import { BsCapslock } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { login } from '../../services/auth';
import { LoginReq } from '../../interfaces/http';
import { useAuth } from '../../contexts/AuthContext';
import { useLogin } from '../../contexts/LoginContext';

const schemaValidationLogin = yup.object({
  email: yup
    .string()
    .email('Format email harus seperti: example@mail.com')
    .required('Mohon masukkan email yang terdaftar'),
  password: yup.string().required('Kata sandi harus diisi').min(8, 'Minimum 8 karakter'),
});

const FormCredential: React.FC = () => {
  const [show, setShow] = React.useState(false);
  const [caps, setCaps] = React.useState(false);
  const handleEyeClick = () => setShow(!show);
  const { setAuth, setUser } = useAuth();
  const { email: registeredEmail } = useLogin();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { from: Location };
  const from = state ? state.from.pathname : '/';

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.getModifierState('CapsLock')) {
      setCaps(true);
    } else {
      setCaps(false);
    }
  };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      email: registeredEmail.value,
      password: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schemaValidationLogin),
  });

  const onSubmit = async (values: LoginReq) => {
    const res = await login(values);

    if (res.success) {
      setAuth({ access_token: res.data.token.access_token });
      setUser(res.data.user);

      navigate(from, { replace: true });
      return;
    }

    setValue('password', '');

    toast({
      description: res.message || 'Unauthorized',
      status: 'error',
      duration: 5000,
    });
  };

  return (
    <Stack spacing={4}>
      <form action="#" method="post" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <FormControl id="email" isInvalid={Boolean(errors.email)}>
            <FormLabel>Email</FormLabel>
            <Input type="email" {...register('email')} />
            <FormHelperText fontSize="xs" display={!errors.email ? 'flex' : 'none'}>
              Contoh: example@mail.com
            </FormHelperText>
            <FormErrorMessage>{errors.email && errors.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl id="password" isInvalid={Boolean(errors.password)}>
            <FormLabel>Kata sandi</FormLabel>
            <InputGroup size="md">
              <Input
                type={show ? 'text' : 'password'}
                pr="3.25rem"
                onKeyUp={e => handleOnKeyUp(e)}
                autoFocus={true}
                {...register('password')}
              />
              <InputRightElement width="3.25rem">
                <Button colorScheme="whiteAlpha" h="1.75rem" size="sm" onClick={handleEyeClick}>
                  {show ? <RiEyeLine color="#CBD5E0" /> : <RiEyeCloseLine color="#CBD5E0" />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormHelperText display={caps && !errors.password ? 'flex' : 'none'} fontSize="xs" color="primary.400">
              <BsCapslock /> <Text ml={1}>Caps Lock is ON</Text>
            </FormHelperText>
            <FormErrorMessage fontSize="xs">{errors.password && errors.password?.message}</FormErrorMessage>
          </FormControl>
          <Stack spacing={4}>
            <Stack direction={{ base: 'column', sm: 'row' }} align="start" justify="end">
              <Link as={RouteLink} to="/forgot-password" fontSize="xs" color="primary.400">
                Lupa kata sandi?
              </Link>
            </Stack>
            <Button
              type="submit"
              colorScheme={useColorModeValue('secondary', 'primary')}
              isLoading={isSubmitting}
              isDisabled={!isValid}
            >
              Masuk
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};

export default FormCredential;
