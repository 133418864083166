import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthState, useAuth } from '../contexts/AuthContext';

export default function withAuth<T extends AuthState = AuthState>(WrappedComponent: React.ComponentType<T>) {
  // Try to create a nice displayName for React Dev Tools.
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithAuth = (props: Omit<T, keyof AuthState>) => {
    // Fetch the props you want to inject. This could be done with context instead.
    const { auth } = useAuth();
    const location = useLocation();

    if (!auth.isAuthenticated) return <Navigate to={'/login'} state={{ from: location }} />;
    // props comes afterwards so the can override the default ones.
    return <WrappedComponent {...auth} {...(props as T)} />;
  };

  ComponentWithAuth.displayName = `withAuth(${displayName})`;

  return ComponentWithAuth;
}
