import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Link,
  useToast,
  Stack,
  HStack,
  Text,
} from '@chakra-ui/react';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import { RiEyeLine, RiEyeCloseLine } from 'react-icons/ri';
import { BsCapslock } from 'react-icons/bs';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { signUp } from '../../services/auth';
import { SignupReq } from '../../interfaces/http';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import logo from '../../logo.svg';
import { useLogin } from '../../contexts/LoginContext';
import SignUpSuccess from './SignUpSuccess';

const schemaValidationLogin = yup.object({
  email: yup
    .string()
    .email('Format email harus seperti: example@mail.com')
    .required('Mohon masukkan email yang terdaftar'),
  firstname: yup
    .string()
    .matches(/^[A-Za-z ]*$/, 'Mohon masukkan hanya huruf alphabet')
    .max(40)
    .required('Mohon masukkan email yang terdaftar'),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#\-!$%^&*()_+|~=`{}\\[\]:\\/;<>?,.@#"'\\]{8,}$/,
      'Min. 8 karakter, berupa kombinasi huruf kecil, huruf besar dan angka'
    )
    .required('Kata sandi harus diisi'),
});

const SignUp: React.FC = () => {
  const { email, setEmail } = useLogin();
  const [show, setShow] = useState<boolean>(false);
  const [caps, setCaps] = useState<boolean>(false);
  const [msgSuccess, setMsgSuccess] = useState<string>('');
  const handleEyeClick = () => setShow(!show);
  const toast = useToast();
  const navigate = useNavigate();

  const handleOnKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.getModifierState('CapsLock')) {
      setCaps(true);
    } else {
      setCaps(false);
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {
      email: email.value,
      firstname: '',
      password: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schemaValidationLogin),
  });

  const onSubmit = async (values: SignupReq) => {
    const res = await signUp(values);
    if (res.success) return setMsgSuccess(res.message);

    toast({
      description: res.message,
      status: 'error',
      duration: 5000,
    });
  };

  useEffect(() => {
    if (
      (email.value && email.isExist && !email.needActivate) ||
      (!email.value && !email.isExist && !email.needActivate)
    )
      return navigate('/login');
  }, [email, navigate]);

  return (
    <Flex minH="100vh" align="center" justify="center">
      {msgSuccess ? (
        <SignUpSuccess>{msgSuccess}</SignUpSuccess>
      ) : (
        <Stack spacing={8} mx="auto" minW={['xs', '442px']} maxW="442px" py={12} px={6}>
          <Stack align="center">
            <Box as={Link} href="/">
              <Image boxSize="64px" src={logo} alt="Logo" fallbackSrc="https://via.placeholder.com/32" />
            </Box>
          </Stack>
          <Box
            rounded="lg"
            // bg={useColorModeValue('white', { base: 'gray.800', sm: 'gray.600' })}
            bg="white"
            boxShadow={['none', 'md']}
            borderWidth={['none', '1px']}
            p={[0, 8]}
          >
            <Box mb={4}>
              <Heading fontSize="xl">Daftar</Heading>
              <Text fontSize="sm">
                Sudah punya akun Ferizy?{' '}
                <Link href="/login" color="primary.400">
                  Log in
                </Link>{' '}
              </Text>
            </Box>
            <Stack spacing={4}>
              <form action="#" method="post" onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={4}>
                  <FormControl id="email" isInvalid={Boolean(errors.email)}>
                    <FormLabel>Email</FormLabel>
                    <HStack alignItems="baseline">
                      <Text>{email.value}</Text>
                      <Link
                        as={RouteLink}
                        to="/login"
                        fontSize="sm"
                        color="primary.400"
                        onClick={() => {
                          setEmail({ ...email, value: '' });
                        }}
                      >
                        Ubah
                      </Link>
                    </HStack>
                  </FormControl>
                  <FormControl id="fullname" isInvalid={Boolean(errors.firstname)}>
                    <FormLabel>Nama lengkap</FormLabel>
                    <Input type="text" {...register('firstname')} />
                    <FormErrorMessage>{Boolean(errors.firstname) && errors.firstname?.message}</FormErrorMessage>
                  </FormControl>
                  <FormControl id="password" isInvalid={Boolean(errors.password)}>
                    <FormLabel>Kata sandi</FormLabel>
                    <InputGroup size="md">
                      <Input
                        type={show ? 'text' : 'password'}
                        pr="3.25rem"
                        onKeyUp={e => handleOnKeyUp(e)}
                        {...register('password')}
                      />
                      <InputRightElement width="3.25rem">
                        <Button colorScheme="whiteAlpha" h="1.75rem" size="sm" onClick={handleEyeClick}>
                          {show ? <RiEyeLine color="#CBD5E0" /> : <RiEyeCloseLine color="#CBD5E0" />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormHelperText fontSize="xs">
                      {caps ? (
                        <HStack color="primary.400">
                          <BsCapslock /> <Text ml={1}>Caps Lock is ON</Text>
                        </HStack>
                      ) : (
                        <Text display={!errors.password ? 'flex' : 'none'}>
                          Min. 8 karakter, berupa kombinasi huruf kecil, huruf besar dan angka
                        </Text>
                      )}
                    </FormHelperText>
                    <FormErrorMessage fontSize="xs" display={!caps ? 'flex' : 'none'}>
                      {Boolean(errors.password) && errors.password?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Stack mt={6}>
                    <Button type="submit" colorScheme="secondary" isLoading={isSubmitting} isDisabled={!isValid}>
                      Masuk
                    </Button>
                  </Stack>
                </Stack>
              </form>
            </Stack>
            <Stack spacing={4} mt={4}>
              <Flex alignItems="center" flexWrap="nowrap">
                <Divider color="gray.400" />
                <Text
                  flexGrow={1}
                  align="center"
                  fontSize="xs"
                  fontWeight="light"
                  px={2}
                  color="gray.400"
                  whiteSpace="nowrap"
                >
                  atau masuk dengan
                </Text>
                <Divider color="gray.400" />
              </Flex>
              <Button w="full" colorScheme="facebook" leftIcon={<FaFacebook />}>
                <Center>
                  <Text>Continue with Facebook</Text>
                </Center>
              </Button>
              <Button w="full" variant="outline" leftIcon={<FcGoogle />}>
                <Center>
                  <Text>Sign in with Google</Text>
                </Center>
              </Button>
            </Stack>
          </Box>
        </Stack>
      )}
    </Flex>
  );
};

export default SignUp;
