import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { useLogin } from '../../contexts/LoginContext';

const SignUpSuccess: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { email } = useLogin();

  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Almost there...
      </Heading>
      <Text color={'gray.500'}>{children}</Text>
      <Text color="gray.300" fontStyle="italic">
        Please check your email ({email.value}) to confirm your account.
      </Text>
    </Box>
  );
};

export default SignUpSuccess;
