import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export default function NotRequireAuth({ children }: { children: JSX.Element }) {
  const { auth } = useAuth();

  if (auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
}
