import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse('D3ED68F7315B7E72');
const iv = CryptoJS.enc.Utf8.parse('D3ED68F7315B7E72');

export const encryptAes = (plainText: string) => {
  try {
    const ciphertext = CryptoJS.AES.encrypt(plainText, key, { iv: iv });

    return ciphertext.toString();
  } catch (err) {
    return false;
  }
};

export const decryptAes = (chiperText: string) => {
  try {
    const plainText = CryptoJS.AES.decrypt(chiperText, key, { iv: iv });

    return plainText.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    return false;
  }
};

export const hashMD5 = (plainText: string) => {
  return CryptoJS.MD5(plainText).toString();
};

export const hashHmacSHA256 = (plainText: string) => {
  return CryptoJS.HmacSHA256(plainText, 'D3ED68F7315B7E72').toString();
};
