import * as React from 'react';
import {
  Flex,
  Box,
  Center,
  Divider,
  Image,
  Link,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebook } from 'react-icons/fa';
import logo from '../../logo.svg';
import FormCredential from './FormCredential';
import FormCheckEmail from './FormCheckEmail';
import { useLogin } from '../../contexts/LoginContext';

const Login: React.FC = () => {
  const { email } = useLogin();

  return (
    <Flex minH="100vh" align="center" justify="center">
      <Stack spacing={8} mx="auto" minW={['xs', '442px']} maxW="lg" py={12} px={6}>
        <Stack align="center">
          <Box as={Link} href="/">
            <Image boxSize="64px" src={logo} alt="Logo" fallbackSrc="https://via.placeholder.com/32" />
          </Box>
        </Stack>
        <Box
          rounded="lg"
          bg={useColorModeValue('white', { base: 'gray.800', sm: 'gray.600' })}
          boxShadow={['none', 'md']}
          borderWidth={['none', '1px']}
          p={[0, 8]}
        >
          <Box mb={4}>
            <Heading fontSize="xl">Masuk {!email.isExist && !email.needActivate && ' / Daftar'}</Heading>
            <Text fontSize="sm">Pesan tiket Ferry menjadi mudah dengan Ferizy</Text>
          </Box>
          {email.isExist && !email.needActivate ? <FormCredential /> : <FormCheckEmail />}
          <Stack spacing={4} mt={4}>
            <Flex alignItems="center" flexWrap="nowrap">
              <Divider color="gray.400" />
              <Text
                flexGrow={1}
                align="center"
                fontSize="xs"
                fontWeight="light"
                px={2}
                color="gray.400"
                whiteSpace="nowrap"
              >
                atau masuk dengan
              </Text>
              <Divider color="gray.400" />
            </Flex>
            <Button w="full" colorScheme="facebook" leftIcon={<FaFacebook />}>
              <Center>
                <Text>Continue with Facebook</Text>
              </Center>
            </Button>
            <Button w="full" variant="outline" leftIcon={<FcGoogle />}>
              <Center>
                <Text>Sign in with Google</Text>
              </Center>
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
