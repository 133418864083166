import { Token, User } from './http';

export enum LoginActionType {
  SET_IS_AUTHORIZED = 'SET_IS_AUTHORIZED',
  SET_IS_FETCHING = 'SET_IS_FETCHING',
  SET_IS_INITIALIZED = 'SET_IS_INITIALIZED',
  SET_USER = 'SET_USER',
}

export const initialUserState: User = {
  id: 0,
  firstname: '',
  gender: '1',
  date_of_birth: new Date(),
  address: '',
  phone_number: '',
  email: '',
  nik: 0,
  updated_profile: 'f',
};

export const initialTokenState: Token = {
  access_token: '',
  token_type: '',
  expires_in: 0,
  // refresh_token: '',
};
