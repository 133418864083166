import React, { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import jwtDecode from 'jwt-decode';
import { Token, User } from '../interfaces/http';
import { initialTokenState, initialUserState } from '../interfaces/state';
import { hashHmacSHA256 } from '../utils/crypto';
import { destroySession } from '../utils/globalHelpers';
import { useNavigate } from 'react-router-dom';
import { logout } from '../services/auth';

type AuthAction =
  | { type: 'SET_USER'; payload: User }
  | { type: 'AUTHENTICATE_USER'; payload: Token }
  | { type: 'AUTHENTICATION_DESTROY' };

export interface AuthState {
  isAuthenticated: boolean;
  token: Token;
  user: User;
}

const initialState: AuthState = {
  isAuthenticated: false,
  token: initialTokenState,
  user: initialUserState,
};

const AuthReducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'AUTHENTICATE_USER':
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload,
      };
    case 'AUTHENTICATION_DESTROY':
      return initialState;
    default:
      return state;
  }
};

const initializeState = () => {
  try {
    const localAccessToken = localStorage.getItem('accessToken') as string;
    // const localRefreshToken = localStorage.getItem('accessToken') as string;
    const localUser = JSON.parse(localStorage.getItem('user') as string);
    const decodeAccess: { sub: string; email: string; iat: number; exp: number } = jwtDecode(localAccessToken);
    const emailHashed = hashHmacSHA256(localUser.email);
    const isAuth = emailHashed === decodeAccess.sub;

    const localInitial = {
      isAuthenticated: isAuth,
      token: {
        access_token: localAccessToken,
        // refresh_token: localRefreshToken,
      },
      user: localUser,
    };

    return localInitial;
  } catch (error) {
    return initialState;
  }
};

type AuthProviderProps = { children: React.ReactNode };

const AuthContext = createContext<{
  auth: AuthState;
  setAuth: (token: Token) => void;
  setUser: (me: User) => void;
  signout: VoidFunction;
}>({
  auth: initialState,
  setAuth: () => null,
  setUser: () => null,
  signout: () => null,
});

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [auth, dispatch] = useReducer(AuthReducer, initializeState());
  const initialRenderAuthState = useRef(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (initialRenderAuthState.current) {
      initialRenderAuthState.current = false;
      return;
    }

    localStorage.setItem('accessToken', auth.token.access_token);
    localStorage.setItem('user', JSON.stringify(auth.user));
  }, [auth]);

  const setAuth = (token: Token) => dispatch({ type: 'AUTHENTICATE_USER', payload: token });
  const setUser = (me: User) => dispatch({ type: 'SET_USER', payload: me });
  const signout = () => {
    logout();
    dispatch({ type: 'AUTHENTICATION_DESTROY' });
    destroySession(() => navigate('/'));
    // window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        setUser,
        signout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
