import axios, { AxiosRequestConfig, AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { refreshToken } from '../services/auth';
import { destroySession } from './globalHelpers';

const interceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const authorization = {
        Authorization: `Basic ${window.btoa('admin:password')}`,
      };
      const newConfig = {
        ...config,
        headers: authorization,
      };

      return newConfig;
    },
    (error: AxiosError) => {
      Promise.reject(error);
    }
  );

  return instance;
};

const interceptorPrivate = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const authorization = {
        Authorization: `Bearer ${localStorage.getItem('accessToken') as string}`,
        'Content-Type': 'application/json',
      };
      const newConfig = {
        ...config,
        headers: authorization,
      };

      return newConfig;
    },
    (error: AxiosError) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res: AxiosResponse) => {
      return res;
    },
    async (error: AxiosError) => {
      const { config, response } = error;

      // Reject promise if usual error
      if (response?.status !== 401) {
        return Promise.reject(error);
      }

      try {
        const getAccessToken = await refreshToken();

        if (!getAccessToken.success) throw new Error(getAccessToken.message || 'Authorization is broken');

        localStorage.setItem('accessToken', getAccessToken.data.access_token);
        if (config && config?.headers) {
          config.headers['Authorization'] = 'Bearer ' + getAccessToken.data.access_token;

          return axios(config);
        }
      } catch (error) {
        // clear localStorage and force log out
        destroySession(() => window.location.reload());

        return Promise.reject(error);
      }
    }
  );

  return instance;
};

const http = interceptor(
  axios.create({
    withCredentials: true,
    timeout: 10000,
  })
);

const httpAuth = interceptorPrivate(
  axios.create({
    timeout: 10000,
  })
);

export { http, httpAuth };
