import { Container, Stack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Navbar from './Navbar';

export default function Main() {
  return (
    <>
      <Navbar />
      <Container as={Stack} maxW="container.lg" sx={{ minHeight: 'calc(100vh - 308px - 61px)' }}>
        <Outlet />
      </Container>
      <Footer />
    </>
  );
}
