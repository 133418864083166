import * as React from 'react';

type EmailType = {
  value: string;
  isExist: boolean;
  needActivate: boolean;
};

type LoginCtxType = {
  email: EmailType;
  setEmail: React.Dispatch<React.SetStateAction<EmailType>>;
};

const LoginCtxDefault: LoginCtxType = {
  email: {
    value: '',
    isExist: false,
    needActivate: false,
  },
  setEmail: (): void => {
    throw new Error('setContext function must be overridden');
  },
};
const LoginContext = React.createContext<LoginCtxType>(LoginCtxDefault);

const LoginProvider = ({ children }: { children: React.ReactNode }) => {
  const [email, setEmail] = React.useState<EmailType>(LoginCtxDefault.email);

  return <LoginContext.Provider value={{ email, setEmail }}>{children}</LoginContext.Provider>;
};

const useLogin = () => {
  const context = React.useContext(LoginContext);
  if (context === undefined) {
    throw new Error('useLogin must be used within an LoginProvider');
  }
  return context;
};

export { useLogin, LoginProvider };
